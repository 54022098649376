import React from "react"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../components/layout"
import * as businessProfileRequirementsStyles from "./business-profile-requirements.module.scss"

const BusinessProfileRequirements = ({ pageContext, location }) => {
  // const pageTitle = "Business Profile Requirements"

  return (
    <>
      <Layout>
        <InnerHeader
          pageTitle="Business Profile Requirements"
          pageContext={pageContext}
          location={location}
        />
        <div className="inner-container inner-y-padding">
          <h2>Completing your business profile packet</h2>
          <p style={{ marginBottom: `1rem` }}>
            [Note: There is NO OPTION TO SAVE PROGRESS while completing the
            form. Submitting all the required information at one time, is the
            best way to ensure accurate and timely processing of your business
            profile.]
          </p>
          <ul className={businessProfileRequirementsStyles.requirementsGrid}>
            {/* FORM A */}
            <li className={businessProfileRequirementsStyles.requirement}>
              <h3 className="h5">
                If your company{" "}
                <span style={{ color: `#9d5918` }}>does not</span> have any
                compliance requirements, complete{" "}
                <span style={{ color: `#9d5918` }}>
                  Business Profile Packet{" "}
                  <span style={{ whiteSpace: `nowrap` }}>Form A</span>
                </span>
              </h3>
              <p style={{ marginBottom: `2rem` }}>
                Join Rocky Today:{" "}
                <a
                  href="https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=c129a31f-81f8-4f23-bdd7-1f941459dea5"
                  target="__blank"
                  title="Profile Packet Form A"
                >
                  Complete Profile Packet Form A
                </a>
              </p>
              <h4 className="h5" style={{ fontSize: `1.1rem` }}>
                The following items are required:
              </h4>
              <ul className="bulleted-list">
                <li>
                  <p>Accounts Payable Contact information</p>
                </li>
                <li>
                  <p>
                    Vendor Compliance/Operations Manager contact information
                  </p>
                </li>
                <li>
                  <p>Trade References</p>
                </li>
                <li>
                  <p>BIN #</p>
                </li>
                <li>
                  <p>Tax ID #</p>
                </li>
                <li>
                  <p>State of Formation</p>
                </li>
                <li>
                  <p>Trade Name or DBA</p>
                </li>
                <li>
                  <p>SIC Code</p>
                </li>
                <li>
                  <p>
                    Upload Resale Certificate or Certificate of Good Standing
                  </p>
                </li>
                <li>
                  <p>Bank name, Contact officer, Account number</p>
                </li>
              </ul>
            </li>
            {/* FORM B */}
            <li className={businessProfileRequirementsStyles.requirement}>
              <h3 className="h5">
                If your company{" "}
                <span style={{ color: `#9d5918` }}>does have</span> any
                compliance requirements, complete{" "}
                <span style={{ color: `#9d5918` }}>
                  Business Profile Packet{" "}
                  <span style={{ whiteSpace: `nowrap` }}>Form B</span>
                </span>
              </h3>
              <p style={{ marginBottom: `2rem` }}>
                Join Rocky Today:{" "}
                <a
                  href="https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=d5f913a3-74b8-44be-9596-143b22812302"
                  target="__blank"
                  title="Profile Packet Form B"
                >
                  Complete Profile Packet Form B
                </a>
              </p>
              <h4 className="h5" style={{ fontSize: `1.1rem` }}>
                The following items are required:
              </h4>
              <ul className="bulleted-list">
                <li>
                  <p>Vendor Guide Link</p>
                </li>
                <li>
                  <p>EDI Contact Information</p>
                </li>
                <li>
                  <p>
                    Upload an image of your required carton label (if
                    applicable)
                  </p>
                </li>
                <li>
                  <p>
                    Upload example of customer specific inserts (if applicable)
                  </p>
                </li>
                <li>
                  <p>
                    Small Package Carrier, Account Number, weight/carton
                    restriction
                  </p>
                </li>
                <li>
                  <p>
                    LTL Carrier, Account Number, weight/height restriction,
                    carton/pallet restriction
                  </p>
                </li>
                <li>
                  <p>Truck Load Carrier</p>
                </li>
                <li>
                  <p>Routing issue contact information</p>
                </li>
                <li>
                  <p>
                    Provide web portal link if this is preferred method of
                    invoicing
                  </p>
                </li>
                <li>
                  <p>Accounts Payable Contact information</p>
                </li>
                <li>
                  <p>
                    Vendor Compliance/Operations Manager contact information
                  </p>
                </li>
                <li>
                  <p>Trade References</p>
                </li>
                <li>
                  <p>BIN #</p>
                </li>
                <li>
                  <p>Tax ID #</p>
                </li>
                <li>
                  <p>State of Formation</p>
                </li>
                <li>
                  <p>Trade Name or DBA</p>
                </li>
                <li>
                  <p>SIC Code</p>
                </li>
                <li>
                  <p>
                    Upload Resale Certificate or Certificate of Good Standing
                  </p>
                </li>
                <li>
                  <p>Bank name, Contact officer, Account number</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Layout>
    </>
  )
}

export default BusinessProfileRequirements
